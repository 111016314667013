import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getScaleFactor } from "../../helpers/scale";
import { PrismaSDK, UsabilityResponse } from "@prismadelabs/prismaid";
import { AppData } from "../../data/AppData";
import { SwipeData } from "../../data/SwipeData";
import SDKSingleton from "../Swipe/SDK";
import {useNavigate} from "react-router-dom";
import Spinner from "../../components/layouts/Spinner";
import bg from "../../assets/img/01_start/start_bg-blurry-01.png";
import useVH from "react-viewport-height";

type Props = {};

function Open(props: Props) {
    const vh = useVH();
    let navigate = useNavigate();
    const [scaleIsReady, setScaleIsReady] = useState(false);
    const invocationIsValid = AppData.useState((s) => s.invocationIsValid);
    const sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
    /*
    * TODO: Fix useInvocationCheck
    *  Fix deviceIsValid ...
    * */

    const initializationSDK = () => {
        AppData.update((s) => {
            s.invocationIsValid = true;
        });

        sdk.getInitialisationSubject().subscribe((response) => {
            if (response.ppi) {
                var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

                if (!Number.isNaN(scale)) {
                    SwipeData.update((s) => {
                        s.scaleFactor = scale;
                    });
                    console.log("scaleFactor", scale);
                }
            } else {
                AppData.update((s) => {
                    s.deviceIsSupported = false;
                });
                console.log("device_not_supported");
            }

            if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
                AppData.update((s) => {
                    s.needsTouchSensitivity = true;
                });
            }

            // wait a little, to allow for possible (but not neccessary usability subject response)
            setTimeout(() => {
                setScaleIsReady(true);
            }, 10);
        });

        sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
            if (response.event === "device_not_supported") {
                AppData.update((s) => {
                    s.deviceIsSupported = false;
                });
                console.log("device_not_supported");
            }

            if (response.event === "browser_not_supported") {
                AppData.update((s) => {
                    s.browserIsSupported = false;
                });
                console.log("browser_not_supported");
            }

            if (response.event === "display_too_small_displacement") {
                AppData.update((s) => {
                    s.screenIsBigEnough = false;
                });
                console.log("display_too_small_displacement");
            }

            if (response.event === "display_small_should_add_to_home") {
                AppData.update((s) => {
                    s.screenIsBigEnough = false;
                });
                console.log("display_small_should_add_to_home");
            }
        });
    }
    useEffect(() => {
        if(sdk && invocationIsValid){
            navigate("/start");
        }else{
            AppData.update((s) => {
                s.invocationIsValid = true;
            });
            initializationSDK();
        }
    }, [sdk]);

    if (scaleIsReady) {
        navigate("/start");
    }

    return (
        <div className="flex flex-col items-center justify-between w-screen pb-5 text-center whitespace-pre-wrap"
             style={{
                 height: `${100 * vh}px`,
                 backgroundRepeat: "no-repeat",
                 backgroundPosition: "center",
             }}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Spinner />
        </div>
    );
}

export default Open;
