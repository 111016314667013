import React, {memo, useEffect, useRef, useState} from "react";
import {
    ConnectivityResponse,
    ConnectivityStatus,
    DecoderResponseError,
    DecoderResponseSuccess,
    InteractionResponse,
    PrismaSDK,
    ProgressResponse,
    Subscription,
} from "@prismadelabs/prismaid";
import SDKSingleton from "./SDK";
import {SwipeData} from "../../data/SwipeData";
import {useNavigate} from "react-router-dom";
import useVH from "react-viewport-height";
import {AnimatePresence, animationControls, motion, useAnimation} from "framer-motion";
import ScaledImage from "../../components/layouts/ScaledImage";
import nude_fluffy_manual from "../../assets/img/02_scan/scan_nude.png";
import green_fluffy_manual from "../../assets/img/02_scan/scan_green.png";
import pink_fluffy_manual from "../../assets/img/02_scan/scan_pink.png";
import nude_fluffy from "../../assets/img/02_scan/scan_nude_4x_preloaded.png";
import green_fluffy from "../../assets/img/02_scan/scan_green_4x_preloaded.png";
import pink_fluffy from "../../assets/img/02_scan/scan_pink_4x_preloaded.png";
import {FluffyData} from "../../data/FluffyData";
import Lottie from "react-lottie-player";
import error_lottie from "../../assets/img/01_start/swipe-gesture.json";
import hand_gesture from "../../assets/img/03_play/hand.json";
import portal_hole from "../../assets/img/02_scan/scan_portal-hole.png";
import swipe_target from "../../assets/img/02_scan/scan_swipe-target.png";
import swipe_area from "../../assets/img/02_scan/scan_swipe-area.png";
import scan_thumb from "../../assets/img/02_scan/scan_thumb_2.png";
import scan_hand from "../../assets/img/02_scan/scan_hand_2.png";
import gray_card from "../../assets/img/02_scan/scan_bg-card.png";
import ProgressBar from "./ProgressBar";
import hiSoundClip from "../../assets/sounds/hi.mp3";
import puff from "../../assets/sounds/puff.mp3";
import empty from "../../assets/sounds/empty.mp3";
import portal_hole_gate_01 from "../../assets/img/02_scan/scan_portal-gateway-01.png";
import portal_hole_gate_02 from "../../assets/img/02_scan/scan_portal-gateway-02.png";
import portal_hole_gate_03 from "../../assets/img/02_scan/scan_portal-gateway-03.png";
import portal_ring_1 from "../../assets/img/02_scan/scan_portal_ring_1.png";
import portal_ring_2 from "../../assets/img/02_scan/scan_portal_ring_2.png";
import ScaledMotionImage from "../../components/layouts/ScaledMotionImage";
import grin_face from "../../assets/img/05_faces/grin.svg";
import smile_face from "../../assets/img/05_faces/smile.svg";
import sweet_face from "../../assets/img/05_faces/sweet.svg";
import chilled_face from "../../assets/img/05_faces/chilled.svg";

type Props = {
    scaleFactor: number;
};

let progressSubject: Subscription | undefined;
let connectivitySubject: Subscription | undefined;
let detectionSuccessSubject: Subscription | undefined;
let detectionErrorSubject: Subscription | undefined;
let interactionSubject: Subscription | undefined;
let sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
let fluffyName: string;
let portal_gateway: any;

function SwipeField(props: Props) {
    let navigate = useNavigate();

    let [errorDetection, setErrorDetection] = useState(false);
    let [isAudioLoaded, setAudioLoaded] = useState(0);
    let [playHandGesture, setPlayHandGesture] = useState(false);
    let [grinFace, setGrinFace] = useState(false);

    const vh = useVH();

    const manual_navigation_animation = useAnimation();
    const manual_variants = {hidden: {opacity: 0}, visible: {opacity: 1}}

    const swipeTargetAnimation = useAnimation();
    const portalAnimationControl02 = useAnimation();
    const portalCardAnimationControl = useAnimation();
    const handAnimationControl = useAnimation();
    const thumbAnimationControl = useAnimation();
    const portal_hole_animation = useAnimation();
    const flashRedAnimation = useAnimation();
    const grin_face_animation = useAnimation();

    //for scanned fluffy transition animation
    const PORTAL_HOLE_SCALE = 0.8;
    const puffSound = useRef(new Audio(puff));
    const emptySound = useRef(new Audio(empty));
    const hiSound = useRef(new Audio(hiSoundClip));

    let atx: any = useRef(null);
    let sourcePuff: any;
    let emptySoundSource: any;
    let hiSoundSource: any;

    useEffect(() => {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        if (window.AudioContext) {
            atx.current = new window.AudioContext();
            let gain = atx.current.createGain();
        }

        if (!sourcePuff) {
            sourcePuff = atx.current.createMediaElementSource(puffSound.current);
            sourcePuff.connect(atx.current.destination);
        }
        if (!emptySoundSource) {
            emptySoundSource = atx.current.createMediaElementSource(emptySound.current);
            emptySoundSource.connect(atx.current.destination);
        }
        if (!hiSoundSource) {
            hiSoundSource = atx.current.createMediaElementSource(hiSound.current);
            hiSoundSource.connect(atx.current.destination);
        }

    }, [atx]);

    const swipeFieldAnimation = useAnimation();
    const fluffyTransitionAnimation = useAnimation();
    const scanFluffyAnimation = useAnimation();
    const scanPortalAnimation01 = useAnimation();
    const scanPortalAnimation02 = useAnimation();
    const scanPortalHoleAnimation = useAnimation();

    const [showTransition, setTransition] = useState(false);

    const showPulse = SwipeData.useState((s) => s.showDot);

    const swipeTargetPoint = useRef<HTMLDivElement>(null);

    let [isAnimateInitial, setAnimateInitial] = useState(0);
    let [isScreenAttached, setScreenAttached] = useState(0);
    let isTransitionCalled = useRef(0);
    let detectedFluffy = useRef('');
    let showFluffy: any = useRef(null);
    let face_skin: any = useRef(null);


    const portalVariants01 = {
        visible: {scale: 1, opacity: 1, transition: {duration: 1, type: "spring", stiffness: 100}},
        hidden: {scale: 0.8, opacity: 0}
    }
    const defaultVariants = {
        visible: {opacity: 1, transition: {duration: 0}},
        hidden: {opacity: 0, transition: {duration: 0}}
    }

    const portalVariants02 = {
        visible: {scale: 1, opacity: 1, transition: {duration: 0.5, type: "spring", stiffness: 500}},
        hidden: {scale: 0.8, opacity: 0}
    }
    const portalCardVariants = {
        visible: {opacity: 1, transition: {duration: 1, ease: "easeIn"}},
        hidden: {opacity: 0, transition: {duration: 0,}},
        detected: {x: "100vw", y: 600, opacity: [1, 0, 0], transition: {duration: 2, ease: "easeOut"}}
    }
    const portalAnimation = {
        visible: {y: 0, scale: 1, opacity: 1, transition: {duration: 1, type: 'spring', stiffness: 80}},
        hidden: {y: 100, scale: 0.7, opacity: 0}
    };

    const handAnimation_variants = {
        show: {opacity: 1, transition: {delay: 0.5, duration: 0,}},
        visible: {y: -900 * props.scaleFactor, transition: {repeat: Infinity, duration: 3, type: "linear"}},
        hidden: {opacity: 0, transition: {duration: 0}}
    };

    const thumbAnimation_variants = {
        visible: {opacity: 1, transition: {duration: 0.5, ease: "easeIn"}},
        hidden: {opacity: 0, scale: 1.1, transition: {duration: 0}}
    };

    const swipe_guide_variants = {
        visible: {delay: 1, opacity: 1, transition: {duration: 0.5, ease: "easeIn"}},
        hidden: {opacity: 0, transition: {duration: 0}}
    };

    const portal_hole_variants = {
        visible: {opacity: 1, transition: {duration: 0.5, ease: "easeIn"}},
        hidden: {opacity: 0, transition: {duration: 0.5, ease: "easeOut"}}
    };

    //after a successful detection
    const scanFluffyVariants = {
        visible: {opacity: 1, transition: {duration: 1}, scale: 2},
        hidden: {opacity: 0, scale: 0.2},
        showTransition: {scale: 0.7, transition: {duration: 1}}
    }
    const scanPortalVariants01 = {
        visible: {rotate: 360, opacity: 1, transition: {duration: 2, ease: "easeOut"}, scale: PORTAL_HOLE_SCALE},
        hidden: {opacity: 0}
    }
    const scanPortalVariants02 = {
        visible: {rotate: -360, opacity: 1, transition: {duration: 2, ease: "easeOut"}, scale: PORTAL_HOLE_SCALE},
        hidden: {opacity: 0}
    }
    const scanPortalHoleVariants = {
        visible: {opacity: 0, transition: {duration: 2, ease: "easeOut"}, scale: PORTAL_HOLE_SCALE},
        hidden: {opacity: 0},
        showPortal: {
            opacity: 1, transition: {duration: 2, ease: "easeOut"}
        }
    }


    /*
    * TODO: Implement SDK
    *  */
    useEffect(() => {
        SwipeData.update((s) => {
            s.networkStatus = ConnectivityStatus.ok;
        });
        sdk.resume();
        progressSubject = sdk.getProgressSubject().subscribe((response: ProgressResponse) => {
            console.log("*) progress:", response.progress);
            SwipeData.update((s) => {
                s.progress = response.progress;
            });
        });

        connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
            console.log("*) connectivity response:", response.status);

            if (response.status === null) return;

            SwipeData.update((s) => {
                s.networkStatus = response.status;
            });
        });

        detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response: DecoderResponseSuccess) => {
            console.log("*) detection success:", response.codeId);
            // TODO: Implement Success Event
            if (response.codeId === "code1" && response.direction === 'up') {
                FluffyData.update((s) => {
                    s.fluffyName = "nude";
                });
                detectedFluffy.current = "nude";
                handleSuccess();
            } else if (response.codeId === "code2" && response.direction === 'up') {
                FluffyData.update((s) => {
                    s.fluffyName = "green";
                });
                detectedFluffy.current = "green";
                handleSuccess();
            } else if (response.codeId === "code5" && response.direction === 'up') {
                FluffyData.update((s) => {
                    s.fluffyName = "pink";
                });
                detectedFluffy.current = "pink";
                handleSuccess();
            } else {
                handleDetectionError();
            }

        });

        detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response: DecoderResponseError) => {
            console.log("*) detection error:", response);
            //handleError();
            handleDetectionError();
        });

        interactionSubject = sdk.getInteractionSubject().subscribe((response: InteractionResponse) => {
            console.log("*) interaction event:", response.event, response.activeSignals);
            resumeAudioContext();
            switch (response.event) {
                case "started":
                    SwipeData.update((s) => {
                        s.showDot = true;
                    });
                    break;
                case "changed":
                    if (response.activeSignals === 1) {
                        SwipeData.update((s) => {
                            s.progress = 0;
                        });
                    }
                    break;
                case "complete":
                    SwipeData.update((s) => {
                        s.showDot = false;
                    });
                    SwipeData.update((s) => {
                        s.progress = 0;
                    });
                    break;
                default:
                    break;
            }
        });

        if (!isScreenAttached) {
            let screen = document.querySelector("#swipeScreen");
            if (screen) {
                sdk.attachToElement(screen);
            }
            setScreenAttached(1);
        }

        return () => {
            if (progressSubject !== undefined) {
                progressSubject.unsubscribe();
            }
            if (connectivitySubject !== undefined) {
                connectivitySubject.unsubscribe();
            }
            if (detectionSuccessSubject !== undefined) {
                detectionSuccessSubject.unsubscribe();
            }
            if (detectionErrorSubject !== undefined) {
                detectionErrorSubject.unsubscribe();
            }
            if (interactionSubject !== undefined) {
                interactionSubject.unsubscribe();
            }
        };
    }, []);

    const handleDetectionError = async () => {
        SwipeData.update((s) => {
            s.progress = 0;
        });
        await flashRedAnimation.start(
            {
                opacity: [100, 0],
                transition: {
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                }
            });
        await flashRedAnimation.start({opacity: 0});
        setErrorDetection(true);
    }
    useEffect(() => {
        return () => {
            puffSound.current.pause();
            emptySound.current.pause();
        }
    }, []);
    const handleSuccess = () => {
        sdk.pause();
        playSound();
        SwipeData.update((s) => {
            s.progress = 0;
        });
        if (detectedFluffy.current === "nude") {
            showFluffy.current = nude_fluffy;
            portal_gateway = portal_hole_gate_01;
            face_skin.current = smile_face;
        } else if (detectedFluffy.current === "green") {
            showFluffy.current = green_fluffy;
            portal_gateway = portal_hole_gate_02;
            face_skin.current = chilled_face;
        } else if (detectedFluffy.current === "pink") {
            showFluffy.current = pink_fluffy;
            portal_gateway = portal_hole_gate_03;
            face_skin.current = sweet_face;
        }

        swipeFieldAnimation.start({opacity: 0, transition: {duration: 0.2, ease: "easeOut"}});
        fluffyTransitionAnimation.start({opacity: 1, transition: {duration: 0.2, ease: "easeIn"}});
        handleFluffyTransition();
    }

    const handleReset = () => {
        console.log("handle reset triggered");
        setTimeout(() => {
            sdk.resetManual();
        }, 200);
    }

    function playSound() {
        puffSound.current.play();
    }

    async function setManualNavigation(e: any, name: string) {
        e.preventDefault();
        await manual_navigation_animation.start("hidden");
        FluffyData.update((s) => {
            s.fluffyName = name;
        });
        detectedFluffy.current = name;
        handleSuccess();
    }

    /*
        portal card codes..
     */
    const animateInitial = async () => {
        await portalCardAnimationControl.start("visible");
        portal_hole_animation.start("hidden");
        await swipeTargetAnimation.start("visible");
        await thumbAnimationControl.start({
            opacity: 1, transition: {duration: 0.5, ease: "easeIn"}
        });
        await handAnimationControl.start("show");
        await handAnimationControl.start("visible");

        isAnimateInitial = 1;
    }

    animateInitial();


    useEffect(() => {
        const animateHand = async () => {
            if (!showPulse) {
                await thumbAnimationControl.start({
                    opacity: 1, transition: {duration: 0.5, ease: "easeIn"}
                });
                await handAnimationControl.start("show");
                await handAnimationControl.start("visible");

            } else if (showPulse) {
                await thumbAnimationControl.start({
                    opacity: 0, transition: {duration: 0}
                });
                await handAnimationControl.start("hidden");
            }
        }
        if (isAnimateInitial) {
            animateHand();
        }

        //to show the outer ring..
        if (showPulse) {
            portalAnimationControl02.start({
                scale: 1,
                opacity: 1,
                transition: {duration: 0.5, type: "spring", stiffness: 500}
            });
        } else {
            portalAnimationControl02.start({opacity: 0, transition: {duration: 0}});
        }

    }, [showPulse]);

    // scanned fluffy transition codes ...
    const handleFluffyTransition = () => {
        scanFluffyAnimation.start("visible");
        setTransition(true);
    };

    const startTransitionAnimation = async () => {
        scanFluffyAnimation.start("showTransition");
        await Promise.all([
            scanPortalAnimation01.start("visible"),
            scanPortalAnimation02.start("visible"),
            scanPortalHoleAnimation.start("visible")
        ]);
        await scanPortalHoleAnimation.start("showPortal");
        handleReset();
        navigate("/fluffy");
    }

    useEffect(() => {
        if (!showPulse && showTransition && isTransitionCalled.current === 0) {
            setPlayHandGesture(true);
            setGrinFace(true);
            scanFluffyAnimation.start({
                rotate: -10,
                transition: {
                    duration: 1
                }
            });
            hiSound.current.play();

            setTimeout(() => {
                scanFluffyAnimation.start({
                    rotate: 0,
                    transition: {
                        duration: 1
                    }
                });
                setGrinFace(false);
                scanFluffyAnimation.start("showTransition");
                startTransitionAnimation();
                isTransitionCalled.current = 1;
            }, 3000);
        }
    }, [showPulse]);

    function resumeAudioContext() {
        if (!isAudioLoaded) {
            emptySound.current.play();
            atx.current.resume();
        } else {
            setAudioLoaded(1);
        }
    }


    /*
       TODO: Render()
       1.
     */
    return (
        <motion.div
            exit={{opacity: 0, transition: {duration: 1, ease: "easeOut"}}}
            className="flex flex-col items-center justify-between w-screen text-center overflow-hidden"
            style={{
                height: `${100 * vh}px`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >

             <motion.div animate={manual_navigation_animation} variants={manual_variants} initial="visible"
                        className="z-10 absolute top-2 right-2">
                <div className="flex flex-col justify-between">
                    <motion.div id="nude" whileTap={{scale: 0.8}} onClick={(e) => setManualNavigation(e, "nude")}
                                style={{WebkitTapHighlightColor: "transparent"}}>
                        <ScaledImage src={nude_fluffy_manual} originalWidth={150} id="nudeIcon" alt=""
                                     className="self-center"/>
                    </motion.div>
                    <motion.div id="green" whileTap={{scale: 0.8}} onClick={(e) => setManualNavigation(e, "green")}
                                style={{WebkitTapHighlightColor: "transparent"}}>
                        <ScaledImage src={green_fluffy_manual} originalWidth={150} id="greenIcon" alt=""
                                     className="self-center"/>
                    </motion.div>
                    <motion.div id="pink" whileTap={{scale: 0.8}} onClick={(e) => setManualNavigation(e, "pink")}
                                style={{WebkitTapHighlightColor: "transparent"}}>
                        <ScaledImage src={pink_fluffy_manual} originalWidth={150} id="pinkIcon" alt=""
                                     className="self-center"/>
                    </motion.div>
                </div>
            </motion.div>



            <div id="swipeScreen" className="absolute top-0 left-0 w-screen z-100 overflow-hidden"
                 style={{height: `${100 * vh}px`}}>

                <AnimatePresence>
                    <motion.div
                        exit={{scale: 0}}
                        animate={fluffyTransitionAnimation}
                        initial="hidden"
                        className="absolute top-0 left-0 w-screen touch-none pointer-events-none"
                        style={{height: `${100 * vh}px`}}
                        variants={defaultVariants}
                    >
                        {showTransition && (
                            <motion.div>
                                {playHandGesture === true && (
                                    <motion.div
                                        className="absolute z-90 bg-transparent"
                                        initial={{opacity: 0}}
                                        animate={{opacity: [100, 0]}}
                                        transition={{
                                            duration: 3,
                                            ease: "linear",
                                        }}
                                        style={{
                                            top: 80 * props.scaleFactor,
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                        }}
                                        onAnimationComplete={() => {
                                            setPlayHandGesture(false);
                                        }}
                                    >
                                        <Lottie loop={1} play={playHandGesture} animationData={hand_gesture}
                                                className="self-center"/>
                                    </motion.div>
                                )}
                                <motion.div
                                    variants={scanPortalVariants01}
                                    animate={scanPortalAnimation01}
                                    initial="hidden"
                                    className="absolute flex justify-center w-full touch-none pointer-events-none"
                                    style={{
                                        bottom: 5 + 30 * props.scaleFactor,
                                    }}
                                >
                                    <ScaledImage src={portal_ring_1} id="transitionPulse1" alt=""
                                                 className="self-center animate-smallping touch-none pointer-events-none"/>
                                </motion.div>
                                <motion.div
                                    variants={scanPortalVariants02}
                                    animate={scanPortalAnimation02}
                                    initial="hidden"
                                    className="absolute flex justify-center w-full"
                                    style={{
                                        bottom: -34 * props.scaleFactor,
                                    }}
                                >
                                    <ScaledImage src={portal_ring_2}
                                                 id="transitionPulse2 touch-none pointer-events-none" alt=""
                                                 className="self-center animate-smallping touch-none pointer-events-none"/>
                                </motion.div>

                                <motion.div
                                    variants={scanPortalHoleVariants}
                                    animate={scanPortalHoleAnimation}
                                    initial="hidden"
                                    className="absolute flex justify-center w-full h-auto"
                                    style={{
                                        bottom: (90) * props.scaleFactor,
                                    }}
                                >
                                    <ScaledImage src={portal_gateway} id="frame" alt=""
                                                 className="self-center touch-none pointer-events-none"/>
                                </motion.div>
                            </motion.div>
                        )}
                        <motion.div
                            className="absolute flex justify-center w-full h-auto touch-none pointer-events-none"
                            style={{
                                bottom: (140) * props.scaleFactor
                            }}
                            variants={scanFluffyVariants}
                            animate={scanFluffyAnimation}
                            initial="hidden"
                        >
                            {grinFace === true && (
                                <motion.div>
                                    <ScaledMotionImage src={grin_face} originalWidth={180} id="smileFluffy" alt=""
                                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                                </motion.div>
                            )}

                            {grinFace === false && (
                                <motion.div>
                                    <ScaledMotionImage src={face_skin.current} originalWidth={180} id="smileFluffy"
                                                       alt=""
                                                       className="self-center z-10 face_center touch-none select-none pointer-events-none"/>
                                </motion.div>
                            )}

                            <ScaledImage src={showFluffy.current} id="fluffy" alt=""
                                         className="self-center touch-none pointer-events-none"/>
                        </motion.div>
                    </motion.div>


                    <motion.div
                        variants={defaultVariants}
                        animate={swipeFieldAnimation}
                        initial="visible"
                    >
                        <ProgressBar/>


                        <AnimatePresence exitBeforeEnter>
                            <motion.div
                                className="absolute inset-0 w-screen bg-red-400 touch-none"
                                initial={{opacity: 0}}
                                animate={flashRedAnimation}
                                style={{
                                    height: `${100 * vh}px`,
                                }}
                            />
                        </AnimatePresence>


                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={portalAnimation}
                            className="absolute top-0 left-0 w-screen touch-none"
                            style={{height: `${100 * vh}px`}}>

                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={portalVariants01}
                                className="absolute flex justify-center w-full pointer-events-none"
                                style={{
                                    bottom: 5 + 30 * props.scaleFactor,
                                }}
                            >
                                <ScaledImage src={portal_ring_1} id="pulse" alt=""
                                             className="self-center animate-smallping"/>
                            </motion.div>

                            {showPulse && (
                                <div className="pointer-events-none">
                                    <AnimatePresence exitBeforeEnter>

                                        <motion.div
                                            variants={portalVariants02}
                                            animate={portalAnimationControl02}
                                            initial="hidden"
                                            className="absolute flex justify-center w-full"
                                            style={{
                                                bottom: -16 + 1 * props.scaleFactor,
                                            }}
                                        >
                                            <ScaledImage src={portal_ring_2} id="pulse2" alt=""
                                                         className="self-center animate-smallping"/>
                                        </motion.div>

                                    </AnimatePresence>
                                </div>
                            )}


                            { /* error animation */}
                            <AnimatePresence exitBeforeEnter>
                                {errorDetection && (
                                    <motion.div
                                        className="absolute z-10 bg-transparent"
                                        initial={{opacity: 0}}
                                        animate={{opacity: [100, 0]}}
                                        transition={{
                                            duration: 10,
                                            ease: "linear",
                                        }}
                                        style={{
                                            top: 100 * props.scaleFactor,
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                        }}
                                        onAnimationComplete={() => {
                                            setErrorDetection(false);
                                        }}
                                    >
                                        <Lottie loop={2} animationData={error_lottie} play={errorDetection}
                                                className="self-center"/>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            <motion.div
                                animate={portal_hole_animation}
                                initial="visible"
                                variants={portal_hole_variants}
                                id="frameContainer"
                                className="absolute flex justify-center w-full h-auto"
                                style={{
                                    bottom: (90) * props.scaleFactor,
                                }}
                            >
                                <ScaledImage src={portal_hole} id="frame" alt=""
                                             className="self-center touch-none"/>
                            </motion.div>

                            {/* Swipe guide area */}
                            <motion.div
                                animate={swipeTargetAnimation}
                                initial="hidden"
                                variants={swipe_guide_variants}
                                className="absolute justify-center"
                                style={{
                                    left: "50%",
                                    bottom: 520 * props.scaleFactor,
                                }}
                            >
                                <motion.div
                                    ref={swipeTargetPoint}
                                    className="absolute justify-center z-10 touch-none pointer-events-none"
                                    style={{
                                        top: -435 * props.scaleFactor,
                                    }}
                                >
                                    <ScaledImage src={swipe_target} id="scan_target" alt="target"
                                                 className="self-center item-center touch-none"/>
                                </motion.div>

                                <ScaledImage src={swipe_area} id="scan_area" alt=""
                                             className="self-center item-center touch-none"/>
                            </motion.div>

                            {/* Showing Thumb and hand */}
                            {!showPulse && (
                                <AnimatePresence exitBeforeEnter>
                                    <motion.div
                                        animate={thumbAnimationControl}
                                        initial="hidden"
                                        variants={thumbAnimation_variants}
                                        className="absolute z-10 pointer-events-none"
                                        style={{
                                            bottom: -230 * props.scaleFactor,
                                            left: -400 * props.scaleFactor,
                                            transform: "translateX(-50%)",
                                        }}
                                    >
                                        <ScaledImage src={scan_thumb} style={{transform: "rotate(20deg)",}}
                                                     id="scan_thumb" alt="" className="self-center touch-none"/>
                                    </motion.div>

                                    <motion.div
                                        animate={handAnimationControl}
                                        initial="hidden"
                                        variants={handAnimation_variants}
                                        className="absolute justify-center w-1/3 z-10 touch-none pointer-events-none"
                                        style={{
                                            left: 780 * props.scaleFactor,
                                            bottom: -250 * props.scaleFactor,
                                        }}
                                    >
                                        <ScaledImage src={scan_hand} id="scan_hand" alt=""
                                                     className="self-center item-center touch-none"/>
                                    </motion.div>
                                </AnimatePresence>
                            )}

                            <motion.div
                                animate={portalCardAnimationControl}
                                initial="hidden"
                                variants={portalCardVariants}
                                id="cardContainer"
                                className="absolute flex justify-center w-full h-auto touch-none pointer-events-none"
                                style={{
                                    bottom: (90) * props.scaleFactor,
                                }}
                            >
                                <ScaledImage src={gray_card} id="card" alt="" className="self-center touch-none"/>
                            </motion.div>

                        </motion.div>
                    </motion.div>
                </AnimatePresence>

            </div>
        </motion.div>
    );
}

export default memo(SwipeField) as any;
